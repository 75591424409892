<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t("__billings_charge") }}</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                :label="$t('__billings_charge_create')"
                v-model="form.create"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-radio-group v-model="feeRegular" row hide-details>
                <v-radio
                  :label="$t('__billings_form_year')"
                  value="year"
                ></v-radio>
                <v-radio
                  :label="$t('__billings_form_month')"
                  value="month"
                ></v-radio>
                <v-radio
                  :label="$t('__billings_form_custom')"
                  value="custom"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="!planStartTime">
            <v-col cols="12">
              <div class="red--text">
                {{ "* " + $t("__nonactivated") }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row v-if="feeRegular === 'year' || feeRegular === 'month'">
                <v-col :cols="isMobile ? 12 : 6">
                  <date-picker
                    :date.sync="form.startDate"
                    :date-label="$t('__billings_form_start_date')"
                    :locale="$i18n.locale"
                    :outlined="true"
                    :dense="true"
                    :disabled="true"
                  />
                </v-col>
                <v-col :cols="isMobile ? 12 : 6">
                  <date-picker
                    :date.sync="form.endDate"
                    :date-label="$t('__billings_form_end_date')"
                    :locale="$i18n.locale"
                    :outlined="true"
                    :dense="true"
                    :min-date="form.startDate"
                    :disabled="true"
                  />
                </v-col>
              </v-row>
              <template v-if="feeRegular === 'custom'">
                <v-select
                  dense
                  outlined
                  :items="customOptions"
                  :label="$t('__billings_form_custom_label')"
                  item-text="ch"
                  item-value="en"
                  v-model="customSelect"
                />
                <v-row v-if="customSelect === 'date'">
                  <v-col :cols="isMobile ? 12 : 6">
                    <date-picker
                      :date.sync="form.startDate"
                      :date-label="$t('__billings_form_custom_start_date')"
                      :locale="$i18n.locale"
                      :outlined="true"
                      :dense="true"
                    />
                  </v-col>
                  <v-col :cols="isMobile ? 12 : 6">
                    <date-picker
                      :date.sync="form.endDate"
                      :date-label="$t('__billings_form_end_date')"
                      :locale="$i18n.locale"
                      :outlined="true"
                      :dense="true"
                      :min-date="form.startDate"
                    />
                  </v-col>
                </v-row>
                <v-row v-else-if="customSelect === 'month'">
                  <v-col cols="12">
                    <v-menu
                      ref="monthPicker"
                      v-model="monthPicker"
                      :close-on-content-click="true"
                      :return-value.sync="month"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          v-model="month"
                          :label="$t('__billings_form_month_label')"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="month"
                        type="month"
                        no-title
                        scrollable
                        :locale="$i18n.locale"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      type="number"
                      v-model.number="form.year"
                      :label="$t('__billings_form_year_label')"
                    />
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <date-picker
                :date.sync="form.deadline"
                :date-label="$t('__billings_deadline')"
                :locale="$i18n.locale"
                :outlined="true"
                :dense="true"
                :min-date="today"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">{{
        $t("__cancel")
      }}</v-btn>
      <v-btn color="blue darken-1" text @click="ok" :disabled="!isValid">{{
        $t("__confirm")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { format, formatISO, addYears, endOfMonth, startOfDay } from "date-fns";

export default {
  name: "BillingForm",
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  },
  props: {
    planStartTime: {
      type: String
    }
  },
  data() {
    return {
      isValid: false,
      feeRegular: "year",
      today: format(new Date(), "yyyy-MM-dd"),
      form: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        startDate: "",
        endDate: "",
        create: true,
        deadline: ""
      },
      month: new Date().toISOString().substr(0, 7),
      monthPicker: false,
      customOptions: [
        {
          ch: this.$t("__billings_form_custom_year"),
          en: "year"
        },
        {
          ch: this.$t("__billings_form_custom_month"),
          en: "month"
        },
        {
          ch: this.$t("__billings_form_custom_date"),
          en: "date"
        }
      ],
      customSelect: "year",
      requiredRule: v => !!v || this.$t("__required")
    };
  },
  methods: {
    ok() {
      let year = 0;
      let month = 0;
      let startDate = this.form.startDate;
      let endDate = this.form.endDate;
      if (this.feeRegular === "custom" && this.customSelect === "month") {
        year = Number(this.month.split("-")[0]);
        month = Number(this.month.split("-")[1]);
        startDate = "";
        endDate = "";
      } else if (this.feeRegular === "custom" && this.customSelect === "year") {
        month = 0;
        year = this.form.year;
        startDate = "";
        endDate = "";
      } else if (this.feeRegular === "custom" && this.customSelect === "date") {
        year = 0;
        month = 0;
      }
      this.$emit("ok", {
        year: year,
        month: month,
        startDate: startDate,
        endDate: endDate,
        create: this.form.create,
        deadline: this.form.deadline
          ? formatISO(startOfDay(new Date(this.form.deadline)))
          : "0001-01-01T00:00:00Z"
      });
      this.init();
    },
    cancel() {
      this.init();
      this.$emit("cancel");
    },
    init() {
      this.form = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        startDate: this.planStartTime,
        endDate: "",
        create: true,
        deadline: ""
      };
      let nextYear = addYears(new Date(this.form.startDate), 1);
      this.form.endDate = format(nextYear, "yyyy-MM-dd");
      this.customSelect = "year";
      this.feeRegular = "year";
    }
  },
  watch: {
    monthPicker: {
      handler(val) {
        if (!val) this.$refs.monthPicker.save(this.month);
      }
    },
    planStartTime: {
      immediate: true,
      handler(val) {
        this.form = {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          startDate: "",
          endDate: "",
          create: true,
          deadline: ""
        };
        if (val) {
          this.form.startDate = val;
          let nextYear = addYears(new Date(this.form.startDate), 1);
          this.form.endDate = format(nextYear, "yyyy-MM-dd");
        }
      }
    },
    feeRegular: {
      handler(val) {
        if (val === "month") {
          let endDate = endOfMonth(new Date(this.form.startDate));
          this.form.endDate = format(endDate, "yyyy-MM-dd");
        } else {
          let nextYear = addYears(new Date(this.form.startDate), 1);
          this.form.endDate = format(nextYear, "yyyy-MM-dd");
        }
      }
    }
  }
};
</script>
