var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"billings-area",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"pt-0"},[_vm._v(" "+_vm._s(_vm.$t("__billings_title"))+" "),_c('v-spacer'),_c('div',[_c('v-select',{staticClass:"mr-3",attrs:{"dense":"","outlined":"","hide-details":"","label":_vm.$t('__billing_status_title'),"items":_vm.statusOptions,"item-text":"ch","item-value":"en"},model:{value:(_vm.statusSelect),callback:function ($$v) {_vm.statusSelect=$$v},expression:"statusSelect"}})],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.toCalcBilling}},[_vm._v(" "+_vm._s(_vm.$t("__billings_charge"))+" ")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.billingList,"items-per-page":5},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.toBilling(item.id)}}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.period !== "0年" ? item.period : item.periodStartDate + " ~ " + item.periodEndDate)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'paid')?_c('v-chip',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t(("__billings_status_" + (item.status))))+" ")]):(item.status === 'unpaid')?_c('v-chip',{attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.$t(("__billings_status_" + (item.status))))+" ")]):_c('v-chip',{attrs:{"color":"grey","dark":""}},[_vm._v(" "+_vm._s(_vm.$t(("__billings_status_" + (item.status))))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.toUpdateBilling(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-update ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__billings_status_update")))])])]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.billingFormDialog),callback:function ($$v) {_vm.billingFormDialog=$$v},expression:"billingFormDialog"}},[_c('BillingForm',{attrs:{"planStartTime":_vm.planStartTime},on:{"ok":_vm.calcBillingOk,"cancel":_vm.calcBillingCancel}})],1),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.updateBillingFormDialog),callback:function ($$v) {_vm.updateBillingFormDialog=$$v},expression:"updateBillingFormDialog"}},[_c('UpdateBillingForm',{attrs:{"data":_vm.updateBillingFormDialogData},on:{"ok":_vm.updateBillingOk,"cancel":_vm.updateBillingCancel}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }