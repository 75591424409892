<template>
  <v-card elevation="0" class="billings-area">
    <v-card-title class="pt-0">
      {{ $t("__billings_title") }}
      <v-spacer />
      <div>
        <v-select
          class="mr-3"
          dense
          outlined
          hide-details
          :label="$t('__billing_status_title')"
          :items="statusOptions"
          item-text="ch"
          item-value="en"
          v-model="statusSelect"
        />
      </div>
      <v-btn color="primary" @click="toCalcBilling">
        {{ $t("__billings_charge") }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table :headers="headers" :items="billingList" :items-per-page="5">
        <template v-slot:[`item.id`]="{ item }">
          <v-btn text small color="primary" @click="toBilling(item.id)">
            {{ item.id }}
          </v-btn>
        </template>
        <template v-slot:[`item.period`]="{ item }">
          {{
            item.period !== "0年"
              ? item.period
              : item.periodStartDate + " ~ " + item.periodEndDate
          }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ item.amount | currency }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip color="success" v-if="item.status === 'paid'">
            {{ $t(`__billings_status_${item.status}`) }}
          </v-chip>
          <v-chip color="error" v-else-if="item.status === 'unpaid'">
            {{ $t(`__billings_status_${item.status}`) }}
          </v-chip>
          <v-chip color="grey" dark v-else>
            {{ $t(`__billings_status_${item.status}`) }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                icon
                @click="toUpdateBilling(item)"
              >
                <v-icon>
                  mdi-update
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("__billings_status_update") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog max-width="600" v-model="billingFormDialog" persistent>
      <BillingForm
        :planStartTime="planStartTime"
        @ok="calcBillingOk"
        @cancel="calcBillingCancel"
      />
    </v-dialog>
    <v-dialog max-width="600" v-model="updateBillingFormDialog" persistent>
      <UpdateBillingForm
        :data="updateBillingFormDialogData"
        @ok="updateBillingOk"
        @cancel="updateBillingCancel"
      />
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { format } from "date-fns";
import BillingForm from "@/components/User/BillingForm";
import UpdateBillingForm from "@/components/User/UpdateBillingForm";

export default {
  name: "BillingsData",
  components: {
    BillingForm,
    UpdateBillingForm
  },
  computed: {
    ...mapGetters({
      billings: "user/billings",
      userPlan: "user/userPlan"
    }),
    billingList() {
      let list = [];
      for (const billing of this.billings) {
        list.push({
          id: billing.id,
          amount: billing.data.amount,
          deadline: format(new Date(billing.data.deadline), "yyyy/MM/dd"),
          status: billing.data.status,
          period: billing.data.month
            ? billing.data.year + "/" + billing.data.month
            : billing.data.year + this.$t("__billings_period_year"),
          periodStartDate: billing.data.year
            ? null
            : format(new Date(billing.data["start_time"]), "yyyy/MM/dd"),
          periodEndDate: billing.data.year
            ? null
            : format(new Date(billing.data["end_time"]), "yyyy/MM/dd")
        });
      }
      return list;
    }
  },
  data() {
    return {
      billingFormDialog: false,
      updateBillingFormDialog: false,
      updateBillingFormDialogData: null,
      headers: [
        {
          text: this.$t("__billings_ID"),
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: this.$t("__billings_period"),
          align: "start",
          value: "period"
        },
        {
          text: this.$t("__billings_amount"),
          value: "amount"
        },
        {
          text: this.$t("__billings_deadline"),
          value: "deadline"
        },
        {
          text: this.$t("__billings_status"),
          value: "status"
        },
        {
          text: this.$t("__billings_actions"),
          sortable: false,
          value: "actions"
        }
      ],
      planStartTime: null,
      statusOptions: [
        {
          ch: this.$t("__billings_status_all"),
          en: "all"
        },
        {
          ch: this.$t("__billings_status_unpaid"),
          en: "unpaid"
        },
        {
          ch: this.$t("__billings_status_paid"),
          en: "paid"
        },
        {
          ch: this.$t("__billings_status_cancelled"),
          en: "cancelled"
        }
      ],
      statusSelect: "all"
    };
  },
  methods: {
    loadBillings(status) {
      let statusParam = status;
      if (status === "all") statusParam = "";
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("user/getBillings", statusParam)
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);

          console.log(err);
        });
    },
    toCalcBilling() {
      this.billingFormDialog = true;
    },
    toUpdateBilling(item) {
      this.updateBillingFormDialogData = item;
      this.updateBillingFormDialog = true;
    },
    toBilling(id) {
      this.$router.push({ name: "UserBilling", params: { id: id } });
    },
    calcBillingOk({ year, month, startDate, endDate, create, deadline }) {
      this.billingFormDialog = false;
      this.$emit("calc", { year, month, startDate, endDate, create, deadline });
    },
    calcBillingCancel() {
      this.billingFormDialog = false;
    },
    updateBillingOk({ billingID, status }) {
      this.updateBillingFormDialogData = null;
      this.updateBillingFormDialog = false;
      this.$emit("update", { billingID, status });
    },
    updateBillingCancel() {
      this.updateBillingFormDialogData = null;
      this.updateBillingFormDialog = false;
    }
  },
  watch: {
    userPlan: {
      handler(val) {
        if (val)
          this.planStartTime = format(
            new Date(val["start_time"]),
            "yyyy-MM-dd"
          );
      }
    },
    statusSelect: {
      handler(val) {
        this.loadBillings(val);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.v-sheet.v-card {
  border-radius: 0;
}

.billings-area {
  border: 1px solid grey;
  padding-top: 16px;
  margin: 0 16px 16px 16px;
  border-radius: none;
}
</style>
