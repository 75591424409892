<template>
  <div class="user-info">
    <v-card elevation="0">
      <v-card-title>
        {{ $t("__userinfo_title") }}
        <br v-if="isMobile" />
        <template v-else> </template>
        {{ userID }}
      </v-card-title>
      <v-card-text>
        <table class="data-table">
          <template v-for="(item, i) in items">
            <tr :key="`data-${i}`">
              <th>{{ item.text }}</th>
              <td v-if="!isMobile">
                <template v-if="user">
                  {{ user[item.key] }}
                  <v-icon
                    v-if="user[item.key] && item.action"
                    color="primary"
                    @click="item.action"
                  >
                    mdi-open-in-new
                  </v-icon>
                </template>
              </td>
            </tr>
            <tr v-if="isMobile" :key="`data-${i}-1`">
              <td class="data-table__subrow_td">
                <template v-if="user">
                  {{ user[item.key] }}
                  <v-icon
                    v-if="user[item.key] && item.action"
                    color="primary"
                    @click="item.action"
                  >
                    mdi-open-in-new
                  </v-icon>
                </template>
              </td>
            </tr>
          </template>
        </table>
        <table class="data-table">
          <tr>
            <th>{{ $t("__user_plan") }}</th>
            <td v-if="!isMobile">
              {{ plan.name ? plan.name : $t("__nonactivated") }}
              <v-chip small color="error" v-if="plan['is_suspended']">
                {{ $t("__set_plan_suspend_true") }}
              </v-chip>
            </td>
          </tr>
          <tr>
            <td v-if="isMobile" class="data-table__subrow_td">
              {{ plan.name ? plan.name : $t("__nonactivated") }}
              <v-chip small color="error" v-if="plan['is_suspended']">
                {{ $t("__set_plan_suspend_true") }}
              </v-chip>
            </td>
          </tr>
          <template v-for="(item, i) in planItems">
            <tr :key="`data-${i}`" v-if="plan.name">
              <th>{{ item.text }}</th>
              <td v-if="!isMobile">
                <template>
                  {{ plan[item.key] }}
                </template>
              </td>
            </tr>
            <tr v-if="isMobile && plan.name" :key="`data-${i}-1`">
              <td class="data-table__subrow_td">
                <template>
                  {{ plan[item.key] }}
                </template>
              </td>
            </tr>
          </template>
          <tr>
            <td colspan="2">
              <v-btn color="primary" dark @click="toSetUserPlan">
                {{ $t("__set_plan") }}
              </v-btn>
              <template v-if="userPlan">
                <v-btn
                  class="ml-2"
                  v-if="userPlan['is_suspended']"
                  @click="toCancelSuspend"
                >
                  {{ $t("__cancel_suspend") }}</v-btn
                >
                <v-btn class="ml-2" v-else @click="toSuspendPlan">
                  {{ $t("__suspend") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </table>

        <table class="data-table">
          <tr>
            <th :rowspan="stores.length + 1">{{ $t("__user_stores") }}</th>
            <td v-if="stores.length === 0">{{ $t("__no_store") }}</td>
          </tr>
          <tr v-for="(store, key) in stores" :key="key">
            <td>
              {{ store.data.name + " / "
              }}{{
                store.data["industry_type"]
                  ? $t(`__industrytype_${store.data["industry_type"]}`)
                  : $t("__unset_industrytype")
              }}
              <v-btn
                outlined
                small
                color="primary"
                class="ml-2"
                @click="toSetIndustryType(store)"
                >{{ $t("__set_industrytype_title") }}</v-btn
              >
            </td>
          </tr>
          <tr>
            <th :rowspan="forms.length + 1">
              {{ $t("__user_create_store_application") }}
            </th>
            <td v-if="forms.length === 0">{{ $t("__no_application") }}</td>
          </tr>
          <tr v-for="(formItem, i) in forms" :key="`formItem-${i}`">
            <td>
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ formItem.data["store_name"] }}
                    <v-btn
                      class="mr-8"
                      fab
                      x-small
                      color="primary"
                      absolute
                      right
                      @click="toFormURL(formItem.id)"
                    >
                      <v-icon dark>mdi-open-in-new</v-icon>
                    </v-btn>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panels accordion flat>
                      <v-expansion-panel
                        v-for="(item, i) in photos"
                        :key="`photos-${i}`"
                      >
                        <v-expansion-panel-header
                          v-if="
                            item.storeOwnerTypes.findIndex(
                              t => t === formItem.data['store_owner_type']
                            ) !== -1 && formItem.data['photo_urls'][item.name]
                          "
                          :key="`photos-${i}`"
                        >
                          {{ $t(`__${item.name}_photo`) }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div class="d-flex justify-center">
                            <v-card
                              class="ma-1"
                              outlined
                              max-width="250"
                              v-for="photoIndex in item.quantity"
                              :key="`photos-${i}-${photoIndex}`"
                            >
                              <v-img
                                contain
                                max-width="100%"
                                v-if="formItem.data['photo_urls'][item.name]"
                                :src="
                                  formItem.data['photo_urls'][item.name][
                                    photoIndex - 1
                                  ]
                                "
                              />
                              <v-btn
                                class="mb-8"
                                fab
                                small
                                color="primary"
                                absolute
                                bottom
                                right
                                @click="
                                  toPhotoURL(
                                    formItem.data,
                                    item.name,
                                    photoIndex - 1
                                  )
                                "
                              >
                                <v-icon dark>mdi-open-in-new</v-icon>
                              </v-btn>
                            </v-card>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="userPlanDialog" persistent max-width="600px">
      <UserPlanForm :plan="plan" @ok="planFormOk" @cancel="planFormCancel" />
    </v-dialog>
    <v-dialog v-model="industryTypeDialog" persistent max-width="600px">
      <IndustryTypeForm
        :store="industryTypeDialogData"
        @ok="industryTypeFormOk"
        @cancel="industryTypeFormCancel"
      />
    </v-dialog>
    <AlertDialog
      :show="alertDialogShow"
      :title="alertDialogTitle"
      :text="alertDialogText"
      :oktext="$t('__confirm')"
      :canceltext="$t('__cancel')"
      @ok="alertDialogOk"
      @cancel="alertDialogCancel"
    />
    <BillingsData @calc="calcBilling" @update="updateBillingStatus" />
    <SearchOrder @orders="loadOrders" :tableData="orderList" :stores="stores" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { format } from "date-fns";
import UserPlanForm from "@/components/ManageUser/UserPlanForm";
import SearchOrder from "@/components/User/SearchOrder.vue";
import BillingsData from "@/components/User/BillingsData.vue";
import IndustryTypeForm from "@/components/User/IndustryTypeForm.vue";

export default {
  name: "UserInfo",
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      userPlan: "user/userPlan",
      data: "user/info",
      stores: "user/stores",
      forms: "user/forms",
      userID: "user/id",
      billings: "user/billings",
      orders: "user/orders"
    }),
    orderList() {
      const list = [];
      for (const order of this.orders) {
        list.push({
          id: order.id,
          createdTime: this.formatTime(order.data["create_time"]),
          price: `$${order.data.price}`,
          status: this.$t(`__${order.data.status}`)
        });
      }
      return list;
    }
  },
  components: {
    SearchOrder,
    UserPlanForm,
    BillingsData,
    IndustryTypeForm
  },
  data() {
    return {
      user: {},
      userPlanDialog: false,
      industryTypeDialog: false,
      industryTypeDialogData: null,
      items: [
        {
          text: this.$t("__first_name"),
          key: "first_name"
        },
        {
          text: this.$t("__last_name"),
          key: "last_name"
        },
        {
          text: this.$t("__email"),
          key: "email"
        },
        {
          text: this.$t("__phone_number"),
          key: "phone_number"
        },
        {
          text: this.$t("__join_time"),
          key: "join_time"
        },
        {
          text: this.$t("__update_time"),
          key: "update_time"
        }
      ],
      planItems: [
        {
          text: "方案價格",
          key: "price"
        },
        {
          text: this.$t("__start_time"),
          key: "start_time"
        },
        {
          text: this.$t("__end_time"),
          key: "end_time"
        },
        {
          text: this.$t("__update_time"),
          key: "update_time"
        }
      ],
      plan: {},
      photos: [
        {
          name: "bank_passbook",
          quantity: 1,
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "location_with_owner",
          quantity: 3,
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "store_sign",
          quantity: 3,
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "environment_with_owner",
          quantity: 3,
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "environment",
          quantity: 2,
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "registration_certificate",
          quantity: 2,
          storeOwnerTypes: ["company"]
        },
        {
          name: "owner_id_card",
          quantity: 2,
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "owner_id_card_with_owner",
          quantity: 2,
          storeOwnerTypes: ["personal"]
        }
      ],
      alertDialogShow: false,
      alertDialogTitle: "",
      alertDialogText: "",
      alertDialogFunc: null,
      alertDialogData: null
    };
  },
  methods: {
    loadUser() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("user/fetchUserInfo", this.$route.params.id)
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
          this.$router.back();
        });
    },
    loadUserPlan() {
      return this.$store
        .dispatch("user/getUserPlan")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.log(err);
        });
    },
    loadBillings() {
      return this.$store
        .dispatch("user/getBillings")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.log(err);
        });
    },
    loadStores() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("user/getUserStores")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    toSetUserPlan() {
      this.userPlanDialog = true;
    },
    toSuspendPlan() {
      this.alertDialogTitle = this.$t("__set_suspend_title");
      this.alertDialogText = this.$t("__set_suspend_text").replace(
        "%1",
        this.data["last_name"] + this.data["first_name"]
      );
      this.alertDialogFunc = this.suspendHandler;
      this.alertDialogData = { userID: this.userID, suspend: true };
      this.alertDialogShow = true;
    },
    toCancelSuspend() {
      this.alertDialogShow = true;
      this.alertDialogTitle = this.$t("__cancel_suspend_title");
      this.alertDialogText = this.$t("__cancel_suspend_text").replace(
        "%1",
        this.data["last_name"] + this.data["first_name"]
      );
      this.alertDialogFunc = this.suspendHandler;
      this.alertDialogData = { userID: this.userID, suspend: false };
      this.alertDialogShow = true;
    },
    suspendHandler({ userID, suspend }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("user/suspendUserPlan", { userID, suspend })
        .then(() => {
          this.loadUserPlan();
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.log(err);
        });
    },
    alertDialogOk() {
      this.alertDialogShow = false;
      if (this.alertDialogFunc) this.alertDialogFunc(this.alertDialogData);
      this.alertDialogTitle = "";
      this.alertDialogText = "";
      this.alertDialogFunc = null;
      this.alertDialogData = null;
    },
    alertDialogCancel() {
      this.alertDialogShow = false;
      this.alertDialogTitle = "";
      this.alertDialogText = "";
      this.alertDialogFunc = null;
      this.alertDialogData = null;
    },
    planFormOk({ planID, startTime, endTime, suspend }) {
      this.$store.dispatch("setIsLoading", true);
      this.userPlanDialog = false;
      return this.$store
        .dispatch("user/setUserPlan", {
          userID: this.userID,
          planID,
          startTime,
          endTime,
          suspend
        })
        .then(() => {
          this.loadUserPlan();
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    planFormCancel() {
      this.userPlanDialog = false;
    },
    loadOrders({ storeID, startTime, endTime }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("user/getOrders", { storeID, startTime, endTime })
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.log(err);
        });
    },
    toPhotoURL(form, name, index) {
      const photoURL = form["photo_urls"][name][index];
      window.open(photoURL);
    },
    toFormURL(formID) {
      this.$router.push(`/create-store-application/${formID}`);
    },
    formatTime(time) {
      if (time === "0001-01-01T00:00:00Z") return "-";
      return format(new Date(time), "yyyy/MM/dd HH:mm:ss");
    },
    calcBilling({ year, month, startDate, endDate, create, deadline }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("user/calcBilling", {
          year,
          month,
          startDate,
          endDate,
          create,
          deadline
        })
        .then(() => {
          this.loadBillings();
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);

          console.log(err);
        });
    },
    updateBillingStatus({ billingID, status }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("user/updateBillingStatus", { billingID, status })
        .then(() => {
          this.loadBillings();
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    toSetIndustryType(store) {
      this.industryTypeDialog = true;
      this.industryTypeDialogData = {
        type: store.data["industry_type"],
        storeID: store.id
      };
    },
    industryTypeFormOk({ storeID, industryType }) {
      this.$store.dispatch("setIsLoading", true);
      this.industryTypeDialog = false;
      return this.$store
        .dispatch("user/setStoreIndustryType", { storeID, industryType })
        .then(() => {
          this.loadStores();
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.log(err);
        });
    },
    industryTypeFormCancel() {
      this.industryTypeDialogData = null;
      this.industryTypeDialog = false;
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(val) {
        if (val) {
          this.user = { ...val };
          this.user["join_time"] = this.formatTime(val["join_time"]);
          this.user["update_time"] = this.formatTime(val["update_time"]);
        }
      }
    },
    userPlan: {
      immediate: true,
      handler(val) {
        if (val) {
          this.plan = { ...val };
          this.plan["start_time"] = this.formatTime(val["start_time"]);
          this.plan["end_time"] = this.formatTime(val["end_time"]);
          this.plan["update_time"] = this.formatTime(val["update_time"]);
          switch (this.plan.name) {
            case "創始商店優惠方案":
              this.plan.price = "NT$40,000";
              break;
            case "贏登賣家-品牌方案":
              this.plan.price = "NT$60,000";
              break;
            case "贏登賣家-新手方案":
              this.plan.price = "NT$8,888";
              break;
            case "個人賣家-商店方案":
              this.plan.price = "NT$52,000";
              break;
            case "個人賣家-新手方案":
              this.plan.price = "NT$40,000";
              break;
            case "公益團體方案":
              this.plan.price = "NT$0";
              break;
            case "公關福利方案":
              this.plan.price = "NT$40,000";
              break;
            default:
              break;
          }
        } else {
          this.plan = {};
        }
      }
    }
  },
  created() {
    this.loadUser().then(() => {
      return this.loadUserPlan();
    });
  }
};
</script>

<style lang="scss">
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-block: 1em;

  th,
  td {
    border: 1px solid silver;
    padding: 8px;
  }
  th {
    width: 1%;
    white-space: nowrap;
    text-align: left;
    color: black;
  }
  td {
    color: black;
  }
  &__subrow_td {
    padding-left: 24px !important;
  }
}
.v-input .day-input-width {
  max-width: 15%;
}
.photo-area {
  border: 1px solid grey;
  // padding-top: 16px;
  margin: 0 16px 16px 16px;
}
</style>
