<template>
  <v-card elevation="0" class="order-area">
    <v-card-title class="pt-0">
      {{ $t("__search_order_title") }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col :cols="isMobile ? 12 : 3">
          <v-select
            dense
            outlined
            :label="$t('__user_stores')"
            :items="storesOptions"
            item-text="name"
            item-value="id"
            v-model="form.storeID"
          />
        </v-col>
        <v-col :cols="isMobile ? 12 : 3">
          <date-picker
            :date.sync="form.startTime"
            :date-label="$t('__start_time')"
            :locale="$i18n.locale"
            :outlined="true"
            :dense="true"
          />
        </v-col>
        <v-col :cols="isMobile ? 12 : 3">
          <date-picker
            :date.sync="form.endTime"
            :date-label="$t('__stop_time')"
            :locale="$i18n.locale"
            :outlined="true"
            :dense="true"
            :min-date="form.startTime"
          />
        </v-col>
        <v-col :cols="isMobile ? 12 : 3">
          <v-btn color="primary" @click="getOrders">{{
            $t("__confirm")
          }}</v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :items="tableData"
        :headers="headers"
        :items-per-page="10"
        class="elevation-1 mt-3 mt-md-0"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SearchOrder",
  props: {
    tableData: {
      type: Array
    },
    stores: {
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    }),
    storesOptions() {
      let options = [];
      for (const store of this.stores) {
        options.push({
          id: store.id,
          name: store.data.name
        });
      }
      return options;
    }
  },
  data() {
    return {
      form: {
        storeID: "",
        startTime: "",
        endTime: ""
      },
      headers: [
        {
          text: this.$t("__order_id"),
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: this.$t("__created_time"),
          value: "createdTime"
        },
        {
          text: this.$t("__price"),
          value: "price"
        },
        { text: this.$t("__status"), sortable: false, value: "status" }
      ]
    };
  },
  methods: {
    getOrders() {
      let formatStartTime = this.formatTime(this.form.startTime);
      let formatEndTime = this.formatTime(this.form.endTime);
      this.$emit("orders", {
        storeID: this.form.storeID,
        startTime: formatStartTime,
        endTime: formatEndTime
      });
    },
    formatTime(time) {
      return new Date(time).toISOString().replace(/Z/g, "+08:00");
    },
    getLastMonthDate() {
      const nowdays = new Date();
      let year = nowdays.getFullYear();
      let month = nowdays.getMonth();
      if (month == 0) {
        month = 12;
        year = year - 1;
      }
      if (month < 10) {
        month = "0" + month;
      }
      let myDate = new Date(year, month, 0);
      let startDate = year + "-" + month + "-01";
      let endDate = year + "-" + month + "-" + myDate.getDate();
      this.form.startTime = startDate;
      this.form.endTime = endDate;
    }
  },
  watch: {
    stores: {
      handler(val) {
        if (val.length) this.form.storeID = val[0].id;
        this.getOrders();
      }
    }
  },
  created() {
    this.getLastMonthDate();
  }
};
</script>
<style scoped>
.v-sheet.v-card {
  border-radius: 0;
}
.order-area {
  border: 1px solid grey;
  padding-top: 16px;
  margin: 0 16px 16px 16px;
  border-radius: none;
}
</style>
