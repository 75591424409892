<template>
  <v-card>
    <v-card-title>
      {{ $t("__set_industrytype_title") }}
    </v-card-title>
    <v-card-text>
      <v-form v-model="isValid">
        <v-select
          outlined
          dense
          :label="$t('__set_industrytype_label')"
          :items="typeOptions"
          item-text="ch"
          item-value="en"
          required
          :rules="[requiredRule]"
          v-model="form.type"
        />
      </v-form>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">
        {{ $t("__cancel") }}
      </v-btn>
      <v-btn color="blue darken-1" text @click="ok" :disabled="!isValid">
        {{ $t("__confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "IndustryTypeForm",
  props: {
    store: {
      type: Object
    }
  },
  data() {
    return {
      form: {
        type: ""
      },
      typeOptions: [
        {
          ch: this.$t("__industrytype_retail"),
          en: "retail"
        },
        {
          ch: this.$t("__industrytype_service"),
          en: "service"
        },
        {
          ch: this.$t("__industrytype_catering"),
          en: "catering"
        }
      ],
      isValid: false,
      requiredRule: v => !!v || this.$t("__required")
    };
  },
  methods: {
    ok() {
      this.$emit("ok", {
        storeID: this.store.storeID,
        industryType: this.form.type
      });
    },
    cancel() {
      this.$emit("cancel");
    }
  },
  watch: {
    store: {
      immediate: true,
      handler(val) {
        this.form.type = "";
        if (!!val && val.type) this.form.type = val.type;
      }
    }
  }
};
</script>
